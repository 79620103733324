// pages/index.tsx
import { NextPage } from 'next';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPaintBrush, faUsers } from '@fortawesome/free-solid-svg-icons';

import Navbar from '@components/navbar';
import BackgroundVideo from '@components/background';
import Footer from '@components/footer';


const Home: NextPage = () => {
  return (
    <div className="font-sans font-medium text-gray-900">
      <Navbar />
      <BackgroundVideo src="/voxel-terrain.mp4" />

      {/* Hero Section */}
      <div className="flex flex-col items-center justify-center h-screen text-center text-white">
        <h1 className="text-5xl md:text-6xl font-bold mb-4">Welcome to Refuge Studios</h1>
        <p className="text-lg md:text-xl max-w-lg mb-8">
          Pioneering the future of interactive 3D graphics with our cutting-edge volumetric and voxel technologies.
        </p>
        <Link
          href="voxelbox"
          className="bg-[#F35774] text-white py-3 px-6 rounded-full hover:bg-[#F57C8B] transition duration-300"
        >
          Discover Our Work
        </Link>
      </div>

      {/* Call to Action Section */}
      <div className="bg-gray-100 py-20 text-center">
        <h2 className="text-4xl font-bold mb-4">Ready to Explore the Future of Graphics?</h2>
        <Link
          href="voxelbox"
          className="bg-white text-black py-3 px-6 rounded-full hover:bg-gray-100 transition duration-300"
        >
          Try Our Demo
        </Link>
      </div>

      <div className="bg-gray-50 py-20 px-6 md:px-12 lg:px-24 text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-800">Who We Are</h2>
        <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">
          At Refuge Studios, we are passionate innovators, pushing the boundaries of interactive 3D graphics.
          Our cutting-edge volumetric and voxel technologies allow us to create immersive experiences that
          blend art and science, empowering creators to shape the future of digital worlds.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
            <FontAwesomeIcon icon={faCube} className="text-[#F35774] w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold mb-2 text-center">Innovative Tech</h3>
            <p className="text-gray-600 text-center">
              Our proprietary volumetric rendering and voxel systems push the boundaries of what's possible in 3D graphics.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
            <FontAwesomeIcon icon={faPaintBrush} className="text-[#F35774] w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold mb-2 text-center">Creative Freedom</h3>
            <p className="text-gray-600 text-center">
              We believe in 3D graphics should be interactive, offering new ways to immerse, interact, and showcase.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
            <FontAwesomeIcon icon={faUsers} className="text-[#F35774] w-12 h-12 mb-4" />
            <h3 className="text-2xl font-bold mb-2 text-center">Community Focused</h3>
            <p className="text-gray-600 text-center">
              We listen to you! Your suggestions and ideas inspire us to build better technology.
            </p>
          </div>
        </div>

        <div className="mt-12">
          <Link
            href="/about-us"
            className="bg-[#F35774] text-white py-3 px-8 rounded-full hover:bg-[#F57C8B] transition duration-300"
          >
            Learn More About Us
          </Link>
        </div>
      </div>



      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
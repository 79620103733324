import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faYoutube, faPatreon } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer id="contact" className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <h4 className="text-xl font-bold mb-4">Follow Us</h4>
        <div className="flex justify-center space-x-6 mb-4">
          <a href="https://x.com/Refuge_Studios" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faTwitter} className="w-8 h-auto" />
          </a>
          <a href="https://www.instagram.com/refugestudios_/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faInstagram} className="w-8 h-auto" />
          </a>
          <a href="https://www.youtube.com/@refuge_studios" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faYoutube} className="w-11 h-auto" />
          </a>
          <a href="https://www.patreon.com/RefugeStudios" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-400">
            <FontAwesomeIcon icon={faPatreon} className="w-8 h-auto" />
          </a>
        </div>

        <hr className="border-gray-700 mb-4" />

        <p className="text-sm mb-1">Need help? Contact us at:</p>
        <a
          href="mailto:support@refugestudios.com.au"
          className="text-blue-200 hover:underline"
        >
          support@refugestudios.com.au
        </a>

        <p className="mt-4 text-sm">&copy; 2024 Refuge Studios. All rights reserved.</p>
      </div>
    </footer>
  );
};


export default Footer;

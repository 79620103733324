import React, { useState } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-black bg-opacity-20 backdrop-blur-md text-white py-4 shadow-lg">
      <div className="container mx-auto flex items-center justify-between px-6">
        <Link href="/" passHref>
          <div className="flex items-center">
            <img
              src="/refuge-logo.png"
              alt="Refuge Studios Logo"
              className="h-10 w-auto mr-3"
            />
            <h1 className="text-1xl font-semibold">Refuge Studios</h1>
          </div>
        </Link>
        <div className="flex items-center md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            {isMobileMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="lg" />
            )}
          </button>
        </div>
        <nav className={`bg-black bg-opacity-0 backdrop-blur-md md:flex ${isMobileMenuOpen ? 'block' : 'hidden'} absolute md:relative   md:bg-transparent w-full md:w-auto top-16 md:top-0 left-0`}>
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 p-4 md:p-0">
            <li>
              <Link href="voxelbox" passHref>
                <span className="text-lg hover:text-pink-400 transition-colors duration-300 cursor-pointer">
                  VoxelBox
                </span>
              </Link>
            </li>
            <li>
              <Link href="models" passHref>
                <span className="text-lg hover:text-pink-400 transition-colors duration-300 cursor-pointer">
                  Models
                </span>
              </Link>
            </li>
            <li>
              <Link href="contact" passHref>
                <span className="text-lg hover:text-pink-400 transition-colors duration-300 cursor-pointer">
                  Contact
                </span>
              </Link>
            </li>
          </ul>
        </nav>
        <Link href="donate" passHref>
          <span className="bg-[#F35774] text-white py-3 px-4 rounded-lg hover:bg-[#F57C8B] transition-all duration-300 flex items-center shadow-lg hover:shadow-xl h-full cursor-pointer">
            <FontAwesomeIcon icon={faHeart} className="mr-2" />
            Donate
          </span>
        </Link>
      </div>
    </header>
  );
};

export default Navbar;
